<template>
    <canvas
        :id="'barcode.' + props.text"
    />
</template>

<script setup>
import JsBarcode from 'jsbarcode';
import {nextTick,defineProps} from "vue";
const props = defineProps(['idcard','text'])

const init = () => {
    let canvas = document.getElementById('barcode.' + props.text);
    if(canvas){
        JsBarcode(canvas , props.idcard, {
            format: "CODE128",  //条形码的格式
            lineColor: "#000000",  //线条颜色
            width:2, //线宽
            height:20,  //条码高度
            displayValue: true, //是否显示文字信息
            fontSize:10,
        });
    }
}
nextTick(()=>{
    setTimeout(()=>{
        init();
    },100)
});
</script>

<style scoped>

</style>