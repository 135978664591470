<template>
    <div class="Tools">
        <el-button @click="Router.go(0)" style="margin-right: 10px">{{langArr['html.el-button.refresh']}}</el-button>
        <el-button v-print="'#printMe'" :disabled="isLoading">{{langArr['html.el-button.print']}}</el-button>
        <el-button @click="makePdf" :disabled="isLoading">{{langArr['html.el-button.makePdf']}}</el-button>
    </div>
    <div class="Main" id="printMe">
        <div class="View" v-for="(item,index) in KeyData" :key="index">
            <div>
                <img :src="getQrCode(item.key)"/>
            </div>
            <Barcode
                :idcard="item.idcard"
                :text="item.text"
            />
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useRoute,useRouter} from 'vue-router'
import {ElMessage,ElMessageBox} from "element-plus";
import {fromBase64} from "js-base64";
import jrQrcode from 'jr-qrcode';
import Barcode from "./KeyDataToPdf/Barcode";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import lang from '../../lang/index';
const langArr = ref(lang.getLang('key','keyDataToPdf'));

const Route = useRoute();
const Router = useRouter();

let isLoading = ref(false);
let KeyData = ref([]);

const init = () => {
    let keyData = Route.params.keyData;
    if (!keyData){
        ElMessageBox.alert(langArr.value['onMounted.error.keyData']);
        return;
    }
    let str = fromBase64(keyData);
    let arr = JSON.parse(str);
    if (!Array.isArray(arr)){
        ElMessageBox.alert(langArr.value['onMounted.error.array']);
        return;
    }
    KeyData.value = arr;
}

const getQrCode = (str) => {
    let data = jrQrcode.getQrBase64(str,{
        width:100,
        height:100,
        padding:10,
    });
    return data;
};

const makePdf = () => {
    ElMessage.info({message:langArr.value['makePdf.wait']});
    isLoading.value = true;
    setTimeout(()=>{
        let domId = 'printMe';
        let dom = document.getElementById(domId);
        html2canvas(dom,{
            useCORS: true, //支持图片跨域
            backgroundColor: 'white',
            scale: 1,
            height: document.getElementById(domId).scrollHeight,
            windowHeight: document.getElementById(domId).scrollHeight
        }).then(canvas=>putPdf(canvas)).catch(()=>isLoading.value = false);
    },2500);
};
const putPdf = (canvas) => {
    var contentWidth = canvas.width;
    var contentHeight = canvas.height;
    //  一页pdf显示html页面生成的canvas高度;
    var pageHeight = contentWidth / 592.28 * 841.89;
    //  未生成pdf的html页面高度
    var leftHeight = contentHeight;
    //  页面偏移
    var position = 0;
    //  a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
    var imgWidth = 595.28;
    var imgHeight = 592.28/contentWidth * contentHeight;
    var pageData = canvas.toDataURL('image/jpeg', 1.0);
    //  l表示横版，p：纵向 （默认纵向）
    var pdf = new jsPDF('', 'pt', 'a4');
    //  有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
    //  当内容未超过pdf一页显示的范围，无需分页
    if (leftHeight < pageHeight) {
        pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
    } else {
        while (leftHeight > 0) {
            pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            //避免添加空白页
            if (leftHeight > 0) {
                pdf.addPage();
            }
        }
    }
    pdf.save("Key.pdf");
    isLoading.value = false;
}

onMounted(()=>{
    init();
});
</script>

<style scoped>
.Tools{
    margin-bottom: 10px;
}
.Main{
    margin: 0 auto;
    width: 900px;
    background-color: white;
    color: white;
}

.View{
    display: inline-block;
    width: 240px;
}

</style>